@media (max-width: 720px) {

  // Reduce the font size. This will effect all derived 'em' font sizes, margins, and padding
  body { font-size: 16px; }
  // reduce h1 and h2 size slightly more
  h1 { font-size: 2em; }
  h2 { font-size: 1.4em; }
    
  article {
    // reduce padding between image and article
    padding-top: 50px;
        
    // reduce margins so article is wider
    .container > header, .container > section, .container > footer {
      margin-left: 2em;
      margin-right: 2em;
    }
        
    // display code blocks inline with text (instead of shifted-left)
    .highlight {
      margin-left: 0em;
      margin-right: 0em;
      // reduce padding of code blocks
      pre { padding: 1em; }
    }
        
    // display full-width images inline with text (instead of shifted-left)
    .full {
      margin-left: 0;
      margin-right: 0;
    }
        
    // Reduce font size and don't extend blockquotes past right margin
    blockquote {
      margin-right: 0;
      p { font-size: 1.2em; }
    }
        
    // reduce hr spacing slightly more
    hr { margin: 1.5em 0; }
  }

  // reduce height of cover image
  .blog-cover, .article-cover { height:200px; }
        
  .blog-cover {
    .cover_overlay .content {
      // reduce size of blog cover text
      h1 { font-size: 25px; }
      h3 { font-size: 17px; }
      // tighten spacing of blog cover text and icons
      a { margin: 1em .5em 0em 0; }
    }
  }
    
  // narrow nav spacing (blog & about links)
  .site-header {
    nav {
      padding-left: 15px;
      padding-right: 15px;
      > a { margin-right: 1em; }
    }
  }
    
  // reconfigure page footer with nav links centered above social links
  .site-footer {
    padding:2em 0;

    .container {
      text-align:center;
        
      nav {
        display:block;
        float:none !important;
        margin: 1.2em 0;
      }
    }
  }
    
  // make contact form full width
  form.contact {
    width: 100%;
    // 16px or larger font prevents iOS from zooming in inputs on focus
    input, textarea { font-size: 16px; }
  }
}

@media (max-width: 400px) {
    
  .blog-cover {
    // hide social icons
    .cover_overlay .content a { display: none; }
  }

  article {
    // reduce margins so article is wider
    .container > header, .container > section, .container > footer {
      margin-left: 1em;
      margin-right: 1em;
    }
        
    // move author name closer to author image
    footer address {
      img { margin-right: 10px; }
    }
  }
}