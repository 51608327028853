@import "globals.scss";

.site-header {
  background: #e8e8e7;

  nav {
    padding: 22px 30px;
    display: flex;

    a {
      color: #444;
      font-weight: 300;
      font-size: 16px;
      text-transform: uppercase;
      &:hover { color:$accent; }
    }

    ul {
      display: flex;
      justify-content: flex-end;
      margin: 0;
      padding: 0;

      li {
        list-style: outside none none;
        padding-left: 1rem;
      }
    }

    .brand {
      flex-grow: 1;
      font-weight: 500;
    }
  }
}
