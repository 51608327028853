@import "globals.scss";

/*
html {
  background: url(/images/gxpbackground5.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
*/

.blog-cover, .article-cover {
  max-width: 100%;
  margin-top: 0;
  position: relative;
  overflow: hidden;
  background: #eee;
  height: 53%;
  max-height: 480px;
  background-color: #111;
  background-size: cover;
  background-position: center center;
}

.blog-cover img, .article-cover img {
    position: absolute;
    top:-100%; left:0; right: 0; bottom:-100%;
    margin: auto;
}

.cover-caption {
  text-align: right;
  font-size: 12px;
  font-style: italic;
  color: #BAB8B3;
  margin-right: .5em;
  a { color: inherit; }
}

.blog-cover {
  height: 320px;

  .cover_overlay {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom:0;
    background-color:rgba(0,0,0,0.20);
    background-image: linear-gradient(bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    background-image: -ms-linear-gradient(bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    z-index:10;

    @include vertical_aligning_container;

    .content {
      display: inline-block;
      vertical-align: middle;
      padding: 0 1em;

      h1 {
//        font-size: 45px;
        line-height:1.4em;
      }

      h3 {
        font-size: 22px;
      }

      h1, h3 {
        color:#fff;
        margin:0;
//        font-weight:600;
      }

      a:last-child {
        margin-right: 0;
      }
      a {
        color:#fff;
        border:0;
        width:36px;
        height:36px;
        border:2px solid rgba(255,255,255,1);
        display:inline-block;
        padding:6px 0;
        border-radius:42px;
        margin:1.5em 1em 0em 0;
        line-height: 0px;
        text-align:center;
        fill:#fff;

        &:hover {
          background:#fff;
          fill:#000;
        }

        svg {
          width: 20px;
          height: 20px;
        }

        .icon {
          fill: inherit;
        }
      }
    }
  }
}
