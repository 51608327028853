@import "globals.scss";

article {
/*  background:#fff; */
  padding:60px 0;

  .container {
    max-width:820px;
    margin:0 auto;
  }

  .container > header, .container > section, .container > footer {
    margin-left: 3.5em;
    margin-right: 3.5em;
  }

  .social {
    height:40px;
    padding:10px 0;
        
    > div {
      display:inline-block;
      width: 100px;
            
      img { vertical-align: baseline; margin: 0; }
      &.reddit { position: relative; top: -1px; }
      &.google { width: 85px; }
      // vertically align facebook like button with other sharing icons
      .fb-like > span { vertical-align: baseline !important; }
      .fb-share-button > span { vertical-align: baseline !important; }
    }
  }

  .post_summary {
    .title { margin: 0em 0 0.6em; }
  }

  header {
    .title {
      margin:0.2em 0;
    }
    .subtitle {
      font-size: 1.4em;
      margin: 0.2em 0;
      color: $textmuted;
      font-weight: 300;
    }
    &:after {
      content:" ";
      display:block;
      width:1.5em;
      height:0.2em;
      background: $border_color;
      margin:1em 0;
    }
  }

  footer {
    margin-top: 1.5em;
        
    address {
      font-size: 0.9em;
      border-top:1px solid $border_color;
      border-bottom:1px solid $border_color;
      padding: 1em 0;
      white-space: nowrap;
      overflow: hidden;
      margin-bottom:1.5em;
        
      p {
        margin:0;
        white-space: normal;
        display: inline-block;
        padding-right: 84px;    // width of img + margin - forces text to wrap correctly
        vertical-align: middle;
      }

      img {
        border-radius: 54px;
        width: 64px;
        height: 64px;
        margin: 0 20px 0 0;
        vertical-align: middle;
      }       
    }
  }

  .meta {
    color:$textmuted;
    font-size:13px;
    text-transform:uppercase;
    letter-spacing:1px;

    time, address { display:inline; }
    a { color:$textmuted; }
  }

  p {
    font-size:1em;
    line-height:1.5;
    color:$text;
    font-weight:400;
    margin:0 0 1.5em;
  }
    
  ol, ul {
    font-weight: 600;
    margin: 0 0 1.5em;
    padding: 0;
    list-style-position: outside;
    padding-left: 1.5em;
        
    li {
      font-weight:400;
      margin-bottom:0.5em;
      p { margin-bottom: 0; }
    }
  }
    
  blockquote {
    border:0;
    margin: 1.5em -3em 1.5em 0;
    padding:0;

    p {
      margin: 0;
      font-size:30px;
      line-height:1.1;
      color:$accent;
      font-weight:600;
    }
  }

  hr {
    margin:2em 0;
    border: 0 none;
    border-top: 1px solid $border_color;
  }

  .highlight {
    margin: 1.85em -3.5em 1.85em 0;
      
    pre {
      border: 0;
      padding: 1.5em 1.5em;
      background: #F5F5F5;
      border: 0;
      border: 1px solid #e0e0e0;
      width:100%;
      font-size:0.8em;
      overflow-x: auto;
      display:block;
        
      code {
        font-size:0.8em;
        border:0;
        padding: 0;
      }
    }
  }

  .full {
    margin: 1.5em -3.5em;

    img {
      max-width:100%;
      margin:0;
      transition:0.5s all ease;
    }

    &.shadow {
      img { box-shadow: 0px 0px 20px #888; }
      // increase margin when shadow is present to compensate for visual space of shadow
      margin-top: 2em;
      margin-bottom: 2em;
    }
    &.rounded img { border-radius:6px; }
    &.border img { border:1px solid $border_color; }
    &.zoomable img { cursor: -webkit-zoom-in; }
    &.zoomable img.zoom { cursor: -webkit-zoom-out; }
    &.zoomable img.zoom {
      -webkit-transform:scale(1.2);
      transition:0.4s all ease;
      box-shadow:0 2px 5px rgba(0,0,0,0.5);
    }
    &.map_container { margin-bottom: 2em; }   // needed because iframe floats in map_container
  }

  // Add a extra margin so spacing between iframes and images and text matches spacing
  // between text blocks. Text blocks have extra spacing because of the line height.
  p > img, .full > img, .full > iframe {
    margin: .4em 0;
  }
}
