/* poiret-one-regular - latin */
@font-face {
  font-family: 'Poiret One';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/poiret-one-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Poiret One'), local('PoiretOne-Regular'),
       url('/fonts/poiret-one-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/poiret-one-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/poiret-one-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/poiret-one-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/poiret-one-v5-latin-regular.svg#PoiretOne') format('svg'); /* Legacy iOS */
}
/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/raleway-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Raleway'), local('Raleway-Regular'),
       url('/fonts/raleway-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/raleway-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/raleway-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/raleway-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/raleway-v12-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}

/* raleway-600 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/raleway-v12-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
       url('/fonts/raleway-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/raleway-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/raleway-v12-latin-600.woff') format('woff'), /* Modern Browsers */
       url('/fonts/raleway-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/raleway-v12-latin-600.svg#Raleway') format('svg'); /* Legacy iOS */
}

