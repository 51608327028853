@import "globals.scss";

.button {
  color:#fff;
  font-weight:bold;
  font-size: 0.8em;
  padding: 0.6em 1.2em;
  display: inline-block;
  position: relative;
  border: 0;
  text-align: center;
  text-decoration: none;
  font-style: normal;
  font-weight:600;
  cursor: pointer;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  vertical-align: bottom;
  text-rendering: optimizeLegibility;
  border-radius: 2px;
  vertical-align:middle;
  border-radius:2px;
  background: $accent;
  border:2px solid $accent;
  border-radius: 3px;
  text-transform: uppercase;
  white-space:nowrap;

  &:hover {
    background:darken($accent, 10%);
    border-color:darken($accent, 10%);
    color:#fff;
  }
    
  &:active {
    box-shadow:none;
  }

  &.outline {
    background: none;
    border-radius: 4px;
    font-size: 0.8em;
    color:darken($accent, 5%);

    &:hover {
      border:2px solid darken($accent, 5%);
      color:darken($accent, 10%);
      background:none;
    }
  }
}
