div.start {
    h1 {
        text-align: center;
        font-size: 2rem;
        padding: 1rem;
        em {
            display: block;
            padding: 1rem;
            font-size: 4rem;
            font-style: normal;
        }
    }

    h2 {
        text-align: center;
        font-size: 2rem;
        margin: 4rem auto 1rem;
        border-bottom: 0.1rem solid #DFDFDF;
        width: 50%;
    }

    .post_summary {
        h3.title {
            text-align: center;
            font-size: 1.5rem;
            margin: 0;
        }

        p {
            text-align: center;
            font-size: 0.9rem;
        }
    }

    ol {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        list-style: none;
        font-size: 1rem;
        li {
            width: 16rem;
            padding: 1rem;
        }
    }

    p {
        text-align: center;
        margin: 0 1rem;
    }
}
