@import "globals.scss";

.site-footer {
  padding: 5em 5em;
  background: #F5F5F5;
  color:#aaa;
  font-size: 13px;

  .container {
    max-width:694px;
    margin:0 auto;
    
    a {
      color:#999;
      &:hover { color:#222; }
    }
    
    nav {
      display:inline-block;
      margin-left: .5em;
    
      a {
        color:#777;
        margin:0 0.3em;
      }
    }
    
    .social {
      float:right;
        
      a:last-child {
        margin-right: 0;
      }
      a {
        color:#000;
        border:0;
        width:36px;
        height:36px;
        border:2px solid rgba(0,0,0,1);
        display:inline-block;
        padding: 6px 0;
        border-radius:42px;
        margin:0 1em 0em 0;
        line-height: 0;
        text-align:center;
        opacity:0.3;

        &:hover {
          opacity:0.9;
        }

        svg {
          width: 20px;
          height: 20px;
        }

        .icon {
          fill: black;
        }
      }

    }
  }
}
