@import "globals.scss";

.unimportant {
  font-size: 70%;
}

address {
  display: block;
  margin-bottom: 1rem;
}
