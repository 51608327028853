
/* Bodytext font */
$font: 'Raleway', sans-serif;

/* Font for headings */
$fontheadings: 'Poiret One', cursive;
$fontsubheadings: 'Poiret One', cursive;

/* Text colors */
$text: #21272d;
$textmuted: #848484;
$accent: #2077b2;
$border_color: #DDD;

@mixin vertical_aligning_container {
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -.34em; // remove space taken up and prevent word-wrapping
  }            
}
